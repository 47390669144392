import React from 'react'
import Content from '../Content'
import { kebabCase } from 'lodash'
import { Link } from 'gatsby'

const ArticleTemplate = ({
  content,
  contentComponent,
  cover,
  meta_title,
  meta_desc,
  tags,
  title,
}) => {
  const PostContent = contentComponent || Content
  return (
    <div>

      <section className='hero is-medium'>
        <div className='hero-body pb-0'>
          <div className='column is-three-fifths is-offset-one-fifth'>
            <h1 className='title is-size-1 has-text-weight-bold pb-5'>
              {title}
            </h1>
            <p className='subtitle'>
              {meta_desc}
            </p>
          </div>
        </div>
      </section>

      <img className='mb-6 mt-6' src={cover} alt={title} />

      <div className='column is-three-fifths is-offset-one-fifth'>
        <PostContent content={content} />

        <div className='mt-6'>
          <h4>Tags</h4>
          <ul className='taglist'>
            {(tags && tags.length)
              ? tags.map(tag => (
                <li key={tag + `tag`}>
                  <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                </li>
              ))
              : null}
          </ul>
        </div>

      </div>
      <hr />
    </div>
  )
}

export default ArticleTemplate
